<template lang="pug">
.administration-view(:class="classes")
  .kt-permissions.row.pt-4.pb-4
    .st-container
      keep-alive
        router-view
</template>

<script>
export default {
  name: 'Administration',

  computed: {
    classes () {
      return {
        'hide-scroll-page': !this.$route.path.includes('employees/add')
      }
    }
  }
}
</script>

<style lang="scss">
.administration-view {
  td {
    vertical-align: middle !important;
  }

  .action-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .st-container {
    position: relative;

    .__rail-is-vertical:before {
      right: 0;
    }

    .__rail-is-horizontal {
      bottom: -6px !important;
    }

    .hasHBar {
      .__panel {
        margin-bottom: -17px;
      }
    }
  }
}

.hide-scroll-page {
  height: calc(100vh - 80px);
  overflow: hidden;
}
</style>
